import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { SlidingDiv } from "../components/sliding";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: "80px",
    maxWidth: "360px",
    wordBreak: "keep-all",

    "& img": {
      marginBottom: "10px",
      width: "100%",
      maxWidth: "360px",
    },
  },
  imageCaption: {
    fontSize: "16px",
    color: "#858585",
  },
}));

interface TimelineItemProps {
  img: string;
  text: string;
}

function TimelineItem ({ img, text }: TimelineItemProps) {
  const classes = useStyles();

  return (
    <SlidingDiv className={classes.root}>
      <img src={img} alt={text} />
      <Typography component="p" className={classes.imageCaption}>
        {text}
      </Typography>
    </SlidingDiv>
  );
};

export default TimelineItem;
