import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import ContainerBox from "../../components/containerBox";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { MainJumbotron } from "../../components/jumbotron";
import TimelineItem from "../../components/timeLineItem";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { CONTENTS, YEAR_ENUM } from "../../data/mileston";

const useStyles = makeStyles(theme => createStyles({
  title: {
    fontSize: "46px",
    fontWeight: 700,
    lineHeight: "1.2",
  },
  timeLine: {
    paddingTop: "180px",
    "& $timeLineYear:nth-child(odd)": {
      [theme.breakpoints.up("xs")]: {
        "& h3:after": {
          content: "''",
          display: "inline-block",
          height: "20px",
          width: "30%",
          marginRight: "-100%",
          marginLeft: "5%",
          borderTop: "2px solid #00dd99",
        },
      },
      [theme.breakpoints.up("sm")]: {
        "& h3:after": {
          content: "''",
          display: "inline-block",
          height: "20px",
          width: "50%",
          marginRight: "-100%",
          marginLeft: "5%",
          borderTop: "2px solid #00dd99",
        },
      },
    },
    "& $timeLineYear:nth-child(even)": {
      flexDirection: "row-reverse",
      "& h3": {
        textAlign: "right",
      },
      [theme.breakpoints.up("xs")]: {
        "& h3:before": {
          content: "''",
          display: "inline-block",
          height: "20px",
          width: "30%",
          marginLeft: "-100%",
          marginRight: "5%",
          borderTop: "2px solid #00dd99",
        },
      },
      [theme.breakpoints.up("sm")]: {
        "& h3:before": {
          content: "''",
          display: "inline-block",
          height: "20px",
          width: "50%",
          marginLeft: "-100%",
          marginRight: "5%",
          borderTop: "2px solid #00dd99",
        },
      },
    },
  },

  timeLineYear: {
    padding: "80px 0",
    display: "flex",
    justifyContent: "space-between",
  },
  year: {
    fontSize: "36px",
    fontWeight: 700,
    marginTop: "-20px",
  },
}));

interface MilestonePageProps {
  pageContext: {
    language: string;
  }
}

function MilestonePage ({ pageContext }: MilestonePageProps) {
  const classes = useStyles();
  const intl = useIntl();
  const { language } = pageContext;

  return (
    <Layout language={language}>
      <SEO title="History" />
      <MainJumbotron />
      <ContainerBox 
        className={classes.timeLine}
      >
        {Object.values(YEAR_ENUM)
          .reverse()
          .map((year)=> (
            <Grid
              key={year}
              component="section"
              container
              className={classes.timeLineYear}
              spacing={4}
            >
              <Grid item md={6} xs={5}>
                <Typography
                  component="h3"
                  gutterBottom
                  className={classes.year}
                >
                  {year}
                </Typography>
              </Grid>
              <Grid item md={6} xs={7}>
                {CONTENTS[year].reverse().map((item) => (
                  <TimelineItem
                    key={item.text}
                    img={item.img}
                    text={intl.formatMessage({ id: item.text })}
                  />
                ))}
              </Grid>
            </Grid>
          ))}
      </ContainerBox>
    </Layout>
  );
};

export default MilestonePage;
